import React from 'react'
import { table } from './table.module.scss'

/******************************
 * Component
 ******************************/

export const TABLE = (props) => {  
  return (
    <table {...props} className={[table, props.className].join(' ')} />
  )
}