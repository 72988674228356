import React from 'react'
import { img, imgLink, imgAlt } from './img.module.scss'
import { P } from './paragraph'

/******************************
 * Component
 ******************************/

export const IMG = (props) => {
  return (
    <div>
      <a
        className={imgLink}
        href={'/imgs/blog/' + props.src}
        target='_blank'>
          <img
            src={'/imgs/blog/' + props.src}
            className={[img, props.className].join(' ')}
          />
      </a>

      {props.alt &&
        <P className={imgAlt}>
          {props.alt}
        </P>
      }
    </div>
  )
}