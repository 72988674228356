import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import RehypeReact from 'rehype-react'

import { header, contentWrapper } from './post.module.scss'

import { H2, H3, H4, H5 } from '../components/headline'
import { P } from '../components/paragraph'
import { UL, OL } from '../components/list'
import { TABLE } from '../components/table'
import { IMG } from '../components/img'
import SEO from '../components/seo'

export const renderAst = new RehypeReact({
  createElement: React.createElement,
  Fragment: Fragment,
  components: {
    h1: H2,
    h2: H3,
    h3: H4,
    h4: H5,
    h5: H5,
    h6: H5,
    p: P,
    // a: A,
    // strong: STRONG,
    ul: UL,
    ol: OL,
    table: TABLE,
    // thead: THEAD,
    // tbody: TBODY,
    // blockquote: BLOCKQUOTE,
    img: IMG,
  },
}).Compiler

const PostLayout = ({ data, location, pageContext }) => {
  const post = data.markdownRemark
  // const { title, excerpt, category } = post.frontmatter
  const { title, category } = post.frontmatter
  const { excerpt } = post
  // const slug = '/' + post.fields.slug
  // const path = '/' + post.fields.path
  // const { contents, prev, next } = pageContext
  // const posts = data.allMarkdownRemark

  // console.log(post.fields)
  // console.log(post.fields)

  return (
    <div>
      <SEO title={title} description={excerpt} pathname={location.pathname} />

      <div className={[header].join(' ')}>
        <h1>{title}</h1>
      </div>
      {/* <p>{excerpt}</p> */}
      
      <div className={[contentWrapper].join(' ')}>
        {renderAst(post.htmlAst)}
      </div>
    </div>
  )
}

export default PostLayout

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      ...PostFields
    }
  }
`